import { Card, CardContent, Divider, Input } from "@mui/material";
import Box from "@mui/material/Box";

export const Search = () => (
    <Card sx={{width: "100%", textAlign: "left", margin: 5}}>
        <CardContent>
            <Input type="text" placeholder="Search notes..." />
        </CardContent>
        <Divider />

    </Card>
)
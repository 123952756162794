import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../contexts/authContext';

export const ProtectedRoute = (props: { children: React.ReactNode }) => {
  const {user} = useContext(AuthContext);
  if (!user.authenticated) {
    return <Navigate to="/" replace />;
  }

  if (user.role !== 'editor') {
    return <Navigate to="/unauthorized" />
  }
  return <>{props.children}</>;
};

export default ProtectedRoute;
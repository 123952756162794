import { BottomNavigation, BottomNavigationAction, Box } from "@mui/material";
import { AsHtml } from './AsHtml';
import { Link } from "react-router-dom";
import { Note } from "../types/Note";
import { Edit } from "@mui/icons-material";
import DeleteIcon from '@mui/icons-material/Delete';

const styles = {
    card: {
      display: 'flex',
      flexDirection: 'column' as const,
      justifyContent: 'space-between',
      width: '200px',
      // height: '250px',
      maxHeight: '250px',
      borderRadius: '8px',
      padding: '16px',
      boxSizing: 'border-box' as const,
      backgroundColor: '#FFFF99',
      color: '#333',
      margin: 5,
      fontSize: 12,
      wordBreak: 'break-all' as const,
    },
    text: {
      flexGrow: 1,
      overflow: 'hidden',
      textAlign: 'center' as const,
      marginBottom: '8px',
    }
  };


export const BoxNote = ({note, id, onDelete}: {note: Note, id: string, onDelete: Function}) => {

  // TODO: investigate why dummy function is required. If it is not there, the delete button works on list load
  const handleDelete = () => {
    onDelete(id)
  }

  return <Box style={styles.card} id={note.id}>
        <Box style={styles.text}>
          <h1><AsHtml html={note.title ?? 'no title'} /></h1>
          <Box sx={{fontSize: '0.1'}}>
            {note.note && <AsHtml html={note.note} />}
          </Box>
        </Box>
        <BottomNavigation>
          <BottomNavigationAction label="Edit" icon={<Edit />} component={Link} to={`/notes/list/${id}`} />
          <BottomNavigationAction label="Delete" icon={<DeleteIcon />} onClick={handleDelete}  />
        </BottomNavigation>
      </Box>
};

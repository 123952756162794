import './App.css';
import { Header } from './components/Header';
import { ThemeProvider } from '@mui/material/styles';
import { Box, Container, CssBaseline } from '@mui/material';
import { Outlet } from 'react-router-dom';
import theme from './theme';
import { useContext } from 'react';
import { AuthContext } from './contexts/authContext';
import { Intro } from './components/Intro';
import { QueryClient, QueryClientProvider } from 'react-query';

export const App = () =>  {
    const { user } = useContext(AuthContext);
    const queryClient = new QueryClient();

    return (<>
        <ThemeProvider theme={theme}>
            <QueryClientProvider client={queryClient}>
            <CssBaseline />
            <Container>
                <Box className="App">
                    <Header/>
                    {user.authenticated && <Outlet />}
                    {!user.authenticated && <Intro />}
                </Box>
            </Container>
          </QueryClientProvider>
        </ThemeProvider>
    </>);
}

export default App;

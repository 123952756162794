import { Box } from "@mui/material";

export const Intro = () => (
    <Box sx={{ display: 'flex', flexDirection: "column", marginLeft: '100px', marginRight: '100px', textAlign: "justify"}}>
        <h1>Welcome to NoteMaster</h1>
        <p>Dans un monde en perpétuelle évolution où l'information circule à une vitesse fulgurante, il devient essentiel de disposer d'outils performants pour organiser ses pensées et ses idées. C'est dans cette optique que nous vous présentons NoteMaster, une application révolutionnaire conçue pour optimiser la prise de notes. NoteMaster ne se contente pas seulement de vous permettre de capturer vos idées en un instant ; elle vous offre également la possibilité de structurer vos notes grâce à un système avancé de labels.</p>
        <p>Avec NoteMaster, chaque note peut être facilement catégorisée en attribuant des labels personnalisés. Cette fonctionnalité permet de regrouper des notes similaires sous des thèmes communs, facilitant ainsi la gestion et la navigation au sein de vos informations. Mais ce n'est pas tout : l'application intègre un puissant moteur de recherche qui vous permet de retrouver rapidement et efficacement vos notes, que ce soit par les labels ou par le texte contenu dans la note elle-même.</p>
        <p>Imaginez ne plus jamais perdre une idée importante ou passer des heures à rechercher une information cruciale. Grâce à l'interface intuitive et élégante de NoteMaster, la prise de notes devient un jeu d'enfant, et l'organisation de vos pensées, un plaisir. Que vous soyez étudiant, professionnel, ou simplement une personne soucieuse de mieux gérer ses idées, NoteMaster est l'outil indispensable pour vous accompagner au quotidien.</p>
        <p>En résumé, NoteMaster est plus qu'une simple application de prise de notes : c'est votre assistant personnel dans la quête d'une productivité accrue et d'une organisation sans faille. Essayez-la dès aujourd'hui et découvrez comment elle peut transformer votre manière de travailler et de penser.</p>
    </Box>
)
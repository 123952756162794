import { useContext, useEffect, useState } from "react"
import { firestoreService } from "../infrastructure/firestore"
import { AuthContext } from "../contexts/authContext"
import { Note } from "../types/Note";

export const Home = () => {
    const { user } = useContext(AuthContext);
    const [docs, setDocs] = useState<Note[]>();
    useEffect(() => {
        async function fetchData() {
            setDocs(await firestoreService.list(user));
        }
        fetchData();
    }, [user]);

    return <>
        <h1>Welcome to Noter</h1>
        <p>This app centralizes your notes</p>
        <p>You have currently {docs && docs.length} notes</p>
    </>
}
import createTheme from "@mui/material/styles/createTheme";
// When using TypeScript 4.x and above
import type {} from '@mui/lab/themeAugmentation';
// When using TypeScript 3.x and below
import '@mui/lab/themeAugmentation';

const theme = createTheme({
    palette: {
        background: {
            default: 'black',
        },
        text: {
            primary: '#FFF',
        },
        primary: {
            main: '#4056A1',
        },
        secondary: {
            main: '#C5CBE3',
        },
    },
    components: {
      MuiTimeline: {
        styleOverrides: {
          root: {
            backgroundColor: 'red',
          },
        },
      },
      MuiButton: {
          styleOverrides: {
            root: {
              color: '#29B6F6',
              borderColor: '#29B6F6',
              backgroundColor: 'transparent',
              '&:hover': {
                backgroundColor: '#29B6F6',
                opacity: '0.5',
                color: 'black',
              },
              '&:selected': {
                textDecoration: 'none',
              },
            },
            outlined: {
              border: '1px solid #29B6F6',
            },
          },
       },
    },
});

export default theme;
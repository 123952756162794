import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../contexts/authContext';
import { firestoreService } from '../../infrastructure/firestore';
import { BoxNote } from '../BoxNote';
import { Box } from '@mui/material';
import { Note } from '../../types/Note';
import Masonry from '@mui/lab/Masonry/Masonry';
import { BoxNoteDeletePopup } from '../BoxNoteDeletePopup';
import { Search } from '../Search';

export const List = () => {
  const [open, setOpen] = useState(false);
  const [items, setItems] = useState<Note[]>();
  const [idToDelete, setIdToDelete] = useState<string>("");
  const { user } = useContext(AuthContext);

  useEffect(() => {
    const fetchData = async () => {
      setItems(await firestoreService.list(user));
    };
    fetchData();
  }, [user]);

  const deleteModal = (id: string) => () => {
    setIdToDelete(id);
    setOpen(true);
  }
  const doDelete = async () => {
    await firestoreService.deleteById(user, idToDelete);
    setOpen(false);
    setItems(await firestoreService.list(user));
  }

  const doCancel = () => {
    setOpen(false);
  }

  return <Box sx={{display: 'flex', flexFlow: 'row wrap', padding: '0px 100px 0px 100px'}}>
    {items && <Search />}
    {items && <Masonry columns={4} spacing={2}>
        {items.map((note) => (
          <BoxNote id={note.id} onDelete={deleteModal(note.id)} note={note} />
        ))}
    </Masonry>}

    <BoxNoteDeletePopup open={open} deleteHandler={doDelete} cancelHandler={doCancel} />
  </Box>
}
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

export const BoxNoteDeletePopup = ({open, deleteHandler, cancelHandler}: { open: boolean, deleteHandler: Function, cancelHandler: Function}) => {

  const handleCancel = () => {
    cancelHandler();
  }

  const handleDelete = async () => {
    deleteHandler();
  }

  return <Dialog id="delete-dialog"
            open={open}
            keepMounted
            // onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle>Your profile</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                 Sure?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button autoFocus onClick={handleCancel}>Cancel</Button>
            <Button onClick={handleDelete}>Delete</Button>
            </DialogActions>
        </Dialog>
};

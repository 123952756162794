// https://firebase.google.com/docs/web/setup#available-libraries
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { GoogleAuthProvider, getAuth, signInWithEmailAndPassword, signInWithPopup } from "firebase/auth";
import { initialUser } from '../contexts/authContext';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAhwUleuC8y4wEhNu1Z4mpdQxi9iy6ZVsQ",
  authDomain: "lannox-noter.firebaseapp.com",
  projectId: "lannox-noter",
  storageBucket: "lannox-noter.appspot.com",
  messagingSenderId: "563910969524",
  appId: "1:563910969524:web:f5ca4ec042f64589412fe1"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);

// https://firebase.google.com/docs/auth/web/password-auth#web
export const auth = getAuth();

export const googleAuth = async () => {
  try {
    const googleUser = await signInWithPopup(auth, new GoogleAuthProvider());
    const token = await googleUser.user.getIdTokenResult();
    const claims = token.claims;
    return {
      authenticated: true,
      email: googleUser.user.email || "",
      uuid: googleUser.user.uid,
      role: claims.role ?? '',
      photoURL: googleUser.user.photoURL
    }
  } catch (error: any) {
    console.log('error', error);
    return initialUser
  }
}

export const emailAuth = async (email: string, password: string) => {
  try {
    const user = await signInWithEmailAndPassword(auth, email, password);
    return {
      authenticated: true,
      email: user.user.email || "",
      uuid: user.user.uid
    }
  } catch(error) {
    return initialUser;
  }
}

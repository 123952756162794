import { createContext, useState } from "react";

export type User = {
  authenticated: boolean;
  email: string;
  uuid: string;
  role: string;
  photoURL?: string;
}
export const initialUser: User = {
  authenticated: false,
  email: "",
  uuid: "",
  role: "",
  photoURL: ""
}

export const AuthContext = createContext<any>(null);

export const AuthContextProvider = (props: { children: React.ReactNode }) => {
  const [user, setUser] = useState<User>(initialUser);

  return (
    <AuthContext.Provider value={{user, setUser}}>
      {props.children}
    </AuthContext.Provider>
  )
}
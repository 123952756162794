import { createBrowserRouter } from "react-router-dom";
import ErrorPage from "../ErrorPage";
import ProtectedRoute from "./ProtectedRoute";
import App from "../App";
import { Logout } from "../components/Logout";
import { List } from "../components/notes/List";
import { Edit } from "../components/notes/Edit";
import { Unauthorized } from "../components/Unauthorized";
import { Home } from "../components/Home";
import { Read } from "../components/notes/Read";

export const router = createBrowserRouter([
  { path: "/", element: <App />, children: [
    { path: "logout", element: <Logout /> },
    { path: "unauthorized", element: <Unauthorized /> },
    { path: "/home", element: <Home /> },
  ]},
  {
      path: "notes",
      element: <ProtectedRoute><App /></ProtectedRoute>,
      errorElement: <ErrorPage />,
      children: [
        {
          path: "list",
          element: <ProtectedRoute><List /></ProtectedRoute>,
        },
        {
          path: "list/:id",
          element: <ProtectedRoute><Edit /></ProtectedRoute>,
        },
        {
          path: "create",
          element: <ProtectedRoute><Edit /></ProtectedRoute>,
        },
        {
          path: "read/:id",
          element: <ProtectedRoute><Read /></ProtectedRoute>,
        }
      ]
    }
  ]);
import { useNavigate } from "react-router-dom";
import { AuthContext, initialUser } from "../contexts/authContext";
import { useContext } from "react";
import { auth } from "../infrastructure/firebase";

export const Logout = () => {
    const navigate = useNavigate();
    const { setUser }  = useContext(AuthContext);

    auth.signOut().then(() => {
        // Sign-out successful.
      }).catch((error) => {
        // An error happened.
      });

    setUser(initialUser);
    navigate("/");
    return <></>
}
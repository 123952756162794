import Box from "@mui/material/Box"
import Chip from "@mui/material/Chip"
import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { uniq } from 'lodash';
import { Input } from "@mui/material";

export const Labels = ({labels, control}: {labels: string[], control: any}) => {
  const [selectedLabels, setSelectedLabels] = useState<string[]>(uniq(labels));
  const [unselectedLabels, setUnselectedLabels] = useState<string[]>([]);

  useEffect(() => {
    const month = new Date().toLocaleString('default', { month: 'long' });
    const year = new Date().getFullYear().toString();
    setUnselectedLabels([month, year]);
  }, []);

  const add = (labelName: string, field: any) => {
    const newSelectedLabels = [...selectedLabels, labelName];
    setSelectedLabels(uniq(newSelectedLabels));
    setUnselectedLabels(unselectedLabels.filter((label) => label !== labelName));
    field.onChange(newSelectedLabels);
  };

  const remove = (labelName: string, field: any) => {
    const newSelectedLabels = selectedLabels.filter((label) => label !== labelName);
    setSelectedLabels(newSelectedLabels);
    setUnselectedLabels([...unselectedLabels, labelName]);
    field.onChange(newSelectedLabels);
  };

  const handleKeyDown = (event: any, field: any) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      const newSelectedLabels = [...selectedLabels, event.target.value];
      setSelectedLabels(newSelectedLabels);
      field.onChange(newSelectedLabels);
      event.target.value = '';
    }
  }
  return (
    <Controller
      name="selectedLabels"
      control={control}
      defaultValue={labels}
      render={({ field }) => (
        <>
        <Box component="fieldset" sx={{ width: '200px', backgroundColor: '#A9A9A9', borderRadius: 2, border: 0, margin: 2 }}>
          <legend style={{ textAlign: 'left', paddingTop: 25 }}>Labels</legend>
          {selectedLabels.map((label: string) => (
            <Chip
              key={label}
              label={label}
              style={{ marginRight: '10px' }}
              clickable={true}
              onClick={() => remove(label, field)}
            />
          ))}
        </Box>
        <Box component="fieldset" sx={{ width: '200px', backgroundColor: '#A9A9A9', borderRadius: 2, border: 0, margin: 2 }}>
          <legend style={{ textAlign: 'left',  paddingTop: 25 }}>Add Labels</legend>
            {unselectedLabels.map((label: string) => (
              <Chip
                key={label}
                label={label}
                style={{ marginRight: '10px' }}
                clickable={true}
                onClick={() => add(label, field)}
              />
            ))}
            <Input
              type="text"
              color="primary"
              name="newTag"
              onKeyDown={(event) => handleKeyDown(event, field)}
          />
        </Box>
        <input type="hidden" {...field} />
        </>
      )}/>
  );

}